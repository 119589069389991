import React, { useEffect, useState } from "react";
import { useAccount, useNetwork, useSwitchNetwork } from "wagmi";
import toast from "react-hot-toast";
import { useWeb3Modal } from "@web3modal/react";

import "./Airdrop.scss";
// import { airdropABI } from "../../utils/abi/AirdropABI";
// import { config } from "../../constants/config";
import { customNetwork } from "../../wagmi";
import { axiosHelper } from "../../config";
import axios from "axios";

// function ipToNumber(ip: string) {
//   return ip.split(".").reduce((acc, octet, index) => {
//     return acc + (parseInt(octet) << ((3 - index) * 8));
//   }, 0);
// }

const Airdrop = () => {
  const { open } = useWeb3Modal();
  const { address } = useAccount();
  const { chain } = useNetwork();
  const { switchNetwork } = useSwitchNetwork();
  const [isLoading, setIsLoading] = useState(false);
  const [isFetching, setIsFetching] = useState(true);
  const [ip, setIP] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await axios.get("https://api.ipify.org?format=json");
        setIP(data.ip);
        setIsFetching(false);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  // const {
  //   data: claimData,
  //   write: claim,
  //   error: isClaimError,
  // } = useContractWrite({
  //   address: config.AIRDROP_CONTRACT_ADDRESS as `0x${string}`,
  //   abi: airdropABI,
  //   functionName: "claimAirdrop",
  // });
  // const { isSuccess, isLoading } = useWaitForTransaction({ hash: claimData?.hash });

  // useEffect(() => {
  //   if (isSuccess) {
  //     toast.success("Token Claimed successfully");
  //   }
  // }, [isSuccess]);

  // useEffect(() => {
  //   if (isClaimError) {
  //     if (
  //       isClaimError.message
  //         .toLowerCase()
  //         .includes("Address not whitelisted for airdrop".toLowerCase())
  //     ) {
  //       toast.error("Address not whitelisted for airdrop");
  //       return;
  //     }
  //     if (isClaimError.message.toLowerCase().includes("Airdrop already claimed".toLowerCase())) {
  //       toast.error("Airdrop already claimed");
  //       return;
  //     }
  //     toast.error("Token claim failed.Please try again later");
  //   }
  // }, [isClaimError]);

  const handleClaim = async () => {
    try {
      if (!address) return;

      setIsLoading(true);
      await axiosHelper.post("/airdrop", { recipient: address, ip });

      toast.success("Airdrop claimed successfully");
    } catch (error) {
      console.log(error);
      toast.error("Airdrop claim failed, please try again later");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="airdrop">
      <div className="swap">
        <div className="card">
          <div className="card-header">
            <h2>Airdrop</h2>
          </div>
          <div className="controls">
            {!address ? (
              <button className="btn-primary" onClick={open}>
                Connect wallet
              </button>
            ) : chain ? (
              chain.unsupported ? (
                <button
                  className="btn-error"
                  onClick={() => switchNetwork?.(customNetwork.id)}
                >
                  switch network
                </button>
              ) : (
                <button
                  disabled={isLoading || isFetching}
                  className="btn-primary"
                  onClick={() => {
                    // claim({
                    //   args: [BigInt(ipToNumber(ip))],
                    // });
                    handleClaim();
                  }}
                >
                  {isFetching
                    ? "Loading..."
                    : isLoading
                    ? "Claiming..."
                    : "Claim"}
                </button>
              )
            ) : (
              <button className="btn-primary" onClick={open}>
                Connect wallet
              </button>
            )}
          </div>
        </div>
      </div>
      <div className="airdrop-bg"></div>
    </div>
  );
};

export default Airdrop;
