import React, { useState } from "react";

import "./Swap.scss";
import CryptoSwap from "./CryptoSwap";
import FiatSwap from "./FiatSwap";
import CardCountdown from "../../components/CardCountdown";
import { useAccount, useBalance } from "wagmi";

const Swap = () => {
  const [tab, setTab] = useState<"crypto" | "fiat">("crypto");
  const { address } = useAccount();
  const { data } = useBalance({ address });

  return (
    <div className="swap">
      <div className="card">
        <div className="card-header">
          <h2>Buy BAZZ in presale</h2>
        </div>
        <CardCountdown />
        <div className="cap">
          <p>$100,000 / $100,000</p>
        </div>
        <div className="progress-bar">
          <div className="progress-strip"></div>
        </div>
        <div className="cap">
          <p>1 BAZZ = 1 USDT</p>
        </div>
        <div className="cap">
          <p>Your purchased Bazz</p>
          <b>{data?.formatted}</b>
        </div>
        <div className="tab">
          <button
            className={tab === "crypto" ? "tab-list active" : "tab-list"}
            onClick={() => setTab("crypto")}
          >
            Crypto
          </button>
          <button
            className={tab !== "crypto" ? "tab-list active" : "tab-list"}
            onClick={() => setTab("fiat")}
          >
            Fiat
          </button>
        </div>
        <div className="swap-form">
          {
            {
              crypto: <CryptoSwap />,
              fiat: <FiatSwap />,
            }[tab]
          }
        </div>
      </div>
    </div>
  );
};

export default Swap;
