import React, { useState } from "react";
import toast from "react-hot-toast";

import logo from "../../assets/images/scan.png";
import { axiosHelper } from "../../config";

export const loadScript = (src: string) => {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
};

const FiatSwap = () => {
  const [loading, setLoading] = useState(false);
  const [buyAmount, setBuyAmount] = useState("");
  const [userAddress, setUserAddress] = useState("");
  const [error, setError] = useState("");

  const handleBuy = async () => {
    try {
      if (!process.env.REACT_APP_RAZORPAY_KEY) return alert("RAZORPAY KEY NOT ADDED");

      setLoading(true);

      const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");

      if (!res) {
        alert("Razorpay SDK failed to load. Are you online?");
        setLoading(false);
        return;
      }

      const result = await axiosHelper.post(`/payment/orders`, {
        amount: Number(buyAmount) * 100,
      });

      if (!result) {
        alert("Server error. Are you online?");
        return;
      }

      const { amount, id: order_id, currency } = result.data;

      const options = {
        key: process.env.REACT_APP_RAZORPAY_KEY,
        amount: amount.toString(),
        currency: currency,
        name: "Bazz",
        description: "Payment",
        image: { logo },
        order_id: order_id,
        handler: async function (response: any) {
          try {
            console.log(response);
            toast.success("payment successfully done.");
            setLoading(false);
            setBuyAmount("");
            setUserAddress("");
          } catch (error) {
            console.log(error);
            setLoading(false);
            toast.error("something went wrong");
          }
        },
        prefill: {},
        notes: {
          address: "Bazz Corporate Office",
          userAddress: userAddress,
        },
        theme: {
          color: "#ffba00",
        },
        modal: {
          ondismiss: function () {
            console.log("Checkout form closed by the user");
            setLoading(false);
            toast.error("payment failed. try again");
          },
        },
      };

      const razorpayWindow = window as any;
      const paymentObject = new razorpayWindow.Razorpay(options);
      paymentObject.open();
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return (
    <div className="form">
      <div className="form-label">
        <label htmlFor="amount">Pay</label>
        <input
          type="number"
          id="amount"
          min={0}
          value={buyAmount}
          onChange={(e) => setBuyAmount(e.target.value)}
          placeholder="0.00"
        />
      </div>
      <div className="form-label">
        <label htmlFor="amount">Receive</label>
        <input type="number" id="amount" min={0} value={buyAmount} readOnly placeholder="0.00" />
      </div>
      <div className="form-label">
        <label htmlFor="userAddress">Enter your wallet address</label>
        <input
          type="text"
          id="userAddress"
          value={userAddress}
          onChange={(e) => setUserAddress(e.target.value)}
          placeholder="0x...."
        />
      </div>
      {error && <p className="error-text">*{error}</p>}
      <button
        className="btn-primary"
        disabled={loading}
        onClick={() => {
          if (Date.now() < 1731522600000) {
            return toast.error("Presale will start from November 14");
          }

          if (!buyAmount || !userAddress) return setError("Please fill required fields");
          setError("");

          handleBuy();
        }}
      >
        {loading ? "Loading..." : "Confirm"}
      </button>
    </div>
  );
};

export default FiatSwap;
