import React from "react";
import Countdown, { CountdownRenderProps } from "react-countdown";

const CardCountdown = () => {
  const renderer = ({ days, hours, minutes, seconds, completed }: CountdownRenderProps) => {
    if (completed) {
      return (
        <>
          <div>
            <h4>00</h4>
            <span>Days</span>
          </div>
          <div>
            <h4>00</h4>
            <span>hours</span>
          </div>
          <div>
            <h4>00</h4>
            <span>minutes</span>
          </div>
          <div>
            <h4>00</h4>
            <span>seconds</span>
          </div>
        </>
      );
    } else {
      return (
        <>
          <div>
            <h4>{days < 10 ? `0${days}` : days}</h4>
            <span>Days</span>
          </div>
          <div>
            <h4>{hours < 10 ? `0${hours}` : hours}</h4>
            <span>hours</span>
          </div>
          <div>
            <h4>{minutes < 10 ? `0${minutes}` : minutes}</h4>
            <span>minutes</span>
          </div>
          <div>
            <h4>{seconds < 10 ? `0${seconds}` : seconds}</h4>
            <span>seconds</span>
          </div>
        </>
      );
    }
  };

  return (
    <div className="countdown">
      <Countdown date={1731522600000} renderer={renderer} />
    </div>
  );
};

export default React.memo(CardCountdown);
