import React, { useEffect, useState } from "react";
import {
  erc20ABI,
  useAccount,
  useContractRead,
  useContractWrite,
  useNetwork,
  useSwitchNetwork,
  useWaitForTransaction,
} from "wagmi";
import { formatEther, parseEther } from "ethers/lib/utils.js";
import toast from "react-hot-toast";
import { useWeb3Modal } from "@web3modal/react";

import { config } from "../../constants/config";
import { ADMIN_WALLET, axiosHelper } from "../../config";
import { customNetwork } from "../../wagmi";

const CryptoSwap = () => {
  const [buyAmount, setBuyAmount] = useState("");
  const { address } = useAccount();
  const { open } = useWeb3Modal();
  const { chain } = useNetwork();
  const { switchNetwork } = useSwitchNetwork();

  const { data } = useContractRead({
    abi: erc20ABI,
    functionName: "balanceOf",
    address: config.TOKEN_ADDRESS as any,
    args: [address as any],
    enabled: Boolean(address),
  });
  const { data: symbol } = useContractRead({
    abi: erc20ABI,
    functionName: "symbol",
    address: config.TOKEN_ADDRESS as any,
  });

  const {
    data: TransferData,
    write: Transfer,
    isError: isTransferError,
    isLoading: isTransferLoading,
  } = useContractWrite({
    address: config.TOKEN_ADDRESS as `0x${string}`,
    abi: erc20ABI,
    functionName: "transfer",
  });
  const { isSuccess, isLoading } = useWaitForTransaction({ hash: TransferData?.hash });

  const handleSendTokens = async () => {
    try {
      await axiosHelper.post("/presale", {
        recipient: address,
        amount: buyAmount,
      });
      toast.success("Bazz token sent to your wallet successfully");
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (isSuccess) {
      toast.success("Token transferred successfully");
      handleSendTokens();
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isTransferError) {
      toast.error("Token transfer failed");
    }
  }, [isTransferError]);

  return (
    <div className="form">
      <div className="flex-between">
        <label>Balance </label>
        <b>
          {!data
            ? 0
            : new Intl.NumberFormat("en-US", {
                minimumFractionDigits: 0,
                maximumFractionDigits: 6,
              }).format(Number(formatEther(data)))}
          &nbsp;{symbol}
        </b>
      </div>
      <div className="form-label">
        <label htmlFor="amount">Pay with USDT</label>
        <input
          type="number"
          id="amount"
          min={0}
          value={buyAmount}
          onChange={(e) => setBuyAmount(e.target.value)}
          placeholder="0.00"
        />
      </div>
      <div className="form-label">
        <label htmlFor="amount">Receive</label>
        <input type="number" id="amount" min={0} value={buyAmount} readOnly placeholder="0.00" />
      </div>
      {!address ? (
        <button className="btn-primary" onClick={open}>
          Connect wallet
        </button>
      ) : chain ? (
        chain.unsupported ? (
          <button className="btn-error" onClick={() => switchNetwork?.(customNetwork.id)}>
            switch network
          </button>
        ) : (
          <button
            disabled={isTransferLoading || isLoading || !buyAmount}
            className="btn-primary"
            onClick={() => {
              if (Date.now() < 1731522600000) {
                return toast.error("Presale will start from November 14");
              }

              Transfer?.({
                args: [ADMIN_WALLET, parseEther(buyAmount).toBigInt()],
              });
            }}
          >
            {!buyAmount ? "Enter an amount" : isLoading ? "Transferring..." : "Confirm"}
          </button>
        )
      ) : (
        <button className="btn-primary" onClick={open}>
          Connect wallet
        </button>
      )}
    </div>
  );
};

export default CryptoSwap;
