import axios from "axios";
import usdtIcon from "./assets/tokens/usdt.png";
import { IChainData } from "./constants/types";

interface Configs {
  [key: string]: IChainData;
}

const serverUrl = process.env.REACT_APP_BACKEND_URL || "https://api.baazcoin.com";

export const axiosHelper = axios.create({ baseURL: `${serverUrl}` });

export const ADMIN_WALLET = "0x4b4945acF86DC1fd136ec387A424eFB6a84Ba406";

const Config: Configs = {
  "56": {
    id: 56,
    name: "Binance Smart Chain",
    network: "Binance-Mainnet",
    explorer_url: "https://bscscan.com",
    RouterContract: "0xFB2E796afc9F98b4255c8C47CecdC5D997bF28d4",
    icon: require("./assets/images/bnb.png"),
    tokens: {
      // W8BIT: {
      //   symbol: "W8BIT",
      //   icon: w8bitIcon,
      //   decimals: 18,
      //   TokenContract: "0x934099689C104Ebd9eB283Ff799540e30d81E5ed",
      //   UnderlyingContract: "0xc26eEDbAE20568522E4Eb7d2CaA6A78875dFcDEc",
      //   minimumAmount: 20000,
      //   native: false,
      //   fee: 3000,
      // },
      USDT: {
        symbol: "USDT",
        icon: usdtIcon,
        decimals: 18,
        TokenContract: "0x55d398326f99059fF775485246999027B3197955",
        UnderlyingContract: "0x55d398326f99059fF775485246999027B3197955",
        minimumAmount: 1,
        native: false,
        fee: 0.5,
      },
    },
  },
  // "97": {
  //   id: 97,
  //   name: "Testnet Binance Smart Chain",
  //   network: "Binance-Testnet",
  //   explorer_url: "https://testnet.bscscan.com",
  //   RouterContract: "0xFB2E796afc9F98b4255c8C47CecdC5D997bF28d4",
  //   icon: require("./assets/images/bnb.png"),
  //   tokens: {
  //     // W8BIT: {
  //     //   symbol: "W8BIT",
  //     //   icon: w8bitIcon,
  //     //   decimals: 18,
  //     //   TokenContract: "0x934099689C104Ebd9eB283Ff799540e30d81E5ed",
  //     //   UnderlyingContract: "0xc26eEDbAE20568522E4Eb7d2CaA6A78875dFcDEc",
  //     //   minimumAmount: 20000,
  //     //   fee: 3000,
  //     //   native: false,
  //     // },
  //     USDT: {
  //       symbol: "USDT",
  //       icon: usdtIcon,
  //       decimals: 18,
  //       TokenContract: "0xeeF3381265a48b1eD7Af3BbdDb27efb5Be644265",
  //       UnderlyingContract: "0xeeF3381265a48b1eD7Af3BbdDb27efb5Be644265",
  //       minimumAmount: 5,
  //       fee: 3000,
  //       native: false,
  //     },
  //   },
  // },
  "1334": {
    id: 1334,
    name: "Baaz Chain",
    network: "Baaz Chain",
    RouterContract: "0xBB5031b55Bca67FD1920404f07b0bb7e331900A5",
    explorer_url: "https://testnet.8bitscan.app",
    icon: require("./assets/images/scan.png"),
    tokens: {
      // W8BIT: {
      //   symbol: "W8BIT",
      //   icon: w8bitIcon,
      //   decimals: 18,
      //   TokenContract: "0x2052b7245D9Bc6Bf7357346AF96a834dAf455617",
      //   UnderlyingContract: "0xbc6378FAae98Fb2207Bb6c35c0f8Ce5846FD4C6c",
      //   minimumAmount: 20000,
      //   fee: 3000,
      //   native: true,
      // },
      USDT: {
        symbol: "USDT",
        icon: usdtIcon,
        decimals: 18,
        TokenContract: "0x39A0c08e28a2A45dA2a60564232f143ED890D154",
        UnderlyingContract: "0x39A0c08e28a2A45dA2a60564232f143ED890D154",
        minimumAmount: 1,
        fee: 0.5,
        native: false,
      },
    },
  },
};

const chainConfig: Array<IChainData> = [
  {
    id: 56,
    name: "Binance Smart Chain",
    network: "Binance-Mainnet",
    explorer_url: "https://bscscan.com",
    RouterContract: "0xFB2E796afc9F98b4255c8C47CecdC5D997bF28d4",
    icon: require("./assets/images/bnb.png"),
    tokens: {
      // W8BIT: {
      //   symbol: "W8BIT",
      //   icon: w8bitIcon,
      //   decimals: 18,
      //   TokenContract: "0x934099689C104Ebd9eB283Ff799540e30d81E5ed",
      //   UnderlyingContract: "0xc26eEDbAE20568522E4Eb7d2CaA6A78875dFcDEc",
      //   minimumAmount: 20000,
      //   native: false,
      //   fee: 3000,
      // },
      USDT: {
        symbol: "USDT",
        icon: usdtIcon,
        decimals: 18,
        TokenContract: "0x55d398326f99059fF775485246999027B3197955",
        UnderlyingContract: "0x55d398326f99059fF775485246999027B3197955",
        minimumAmount: 1,
        native: false,
        fee: 0.5,
      },
    },
  },
  // {
  //   id: 97,
  //   name: "Testnet Binance Smart Chain",
  //   network: "Binance-Testnet",
  //   explorer_url: "https://testnet.bscscan.com",
  //   RouterContract: "0xFB2E796afc9F98b4255c8C47CecdC5D997bF28d4",
  //   icon: require("./assets/images/bnb.png"),
  //   tokens: {
  //     // W8BIT: {
  //     //   symbol: "W8BIT",
  //     //   icon: w8bitIcon,
  //     //   decimals: 18,
  //     //   TokenContract: "0x934099689C104Ebd9eB283Ff799540e30d81E5ed",
  //     //   UnderlyingContract: "0xc26eEDbAE20568522E4Eb7d2CaA6A78875dFcDEc",
  //     //   minimumAmount: 20000,
  //     //   native: false,
  //     //   fee: 3000,
  //     // },
  //     USDT: {
  //       symbol: "USDT",
  //       icon: usdtIcon,
  //       decimals: 18,
  //       TokenContract: "0xDd4B8735f58C075E7Ccd8fAA7D53B1DaFB1Dd44A",
  //       UnderlyingContract: "0xCe85c3C5DF7dAF4524371B4014998052f5233e35",
  //       minimumAmount: 5,
  //       native: false,
  //       fee: 0.5,
  //     },
  //   },
  // },
  {
    id: 1334,
    name: "Baaz Chain",
    network: "Baaz Chain",
    RouterContract: "0xBB5031b55Bca67FD1920404f07b0bb7e331900A5",
    explorer_url: "https://baazscan.com/",
    icon: require("./assets/images/scan.png"),
    tokens: {
      // W8BIT: {
      //   symbol: "W8BIT",
      //   icon: w8bitIcon,
      //   decimals: 18,
      //   TokenContract: "0x2052b7245D9Bc6Bf7357346AF96a834dAf455617",
      //   UnderlyingContract: "0xbc6378FAae98Fb2207Bb6c35c0f8Ce5846FD4C6c",
      //   minimumAmount: 20000,
      //   native: true,
      //   fee: 3000,
      // },
      USDT: {
        symbol: "USDT",
        icon: usdtIcon,
        decimals: 18,
        TokenContract: "0x39A0c08e28a2A45dA2a60564232f143ED890D154",
        UnderlyingContract: "0x39A0c08e28a2A45dA2a60564232f143ED890D154",
        minimumAmount: 1,
        native: false,
        fee: 0.5,
      },
    },
  },
];
export { Config, chainConfig, serverUrl };
